<template>
    <div class="container pdx-15-sm pdx-15">
        <p class="go-back">
            <router-link :to="{name: 'Catalog', params: {slug: sectionPath, slug2: categoryPath}}">
                Вернуться в категорию
            </router-link>
        </p>
        <h2 class="title title-without-line">
            <span>{{ name }}</span>
            <span v-if="quantity && quantity > 1">
                ({{ quantity }} шт.)
            </span>
        </h2>
        <div class="row">
            <div class="col-lg-4 col-xl-4 col-sm-12 col-md-12 product_example">
                <div v-if="hasProductImages" class="d-flex">
                    <div class="vertical-imgs-section images">
                        <div class="vertical-imgs" v-for="(image, key) in images" :key="key">
                            <img
                              alt=""
                              class="item"
                              :src="getProductImageSrc(image)"
                              @click="selectImage(image, key); show()"
                            />
                        </div>
                    </div>
                    <div class="big-img">
                        <img
                            v-if="selectedImage"
                            :src="getProductImageSrc(selectedImage)"
                            alt=""
                            @click="show"
                        >
                        <img
                            v-else
                            alt=""
                            src="@/assets/images/default-product-image.jpg"
                        >
                    </div>
                </div>
                <template v-else>
                    <img alt="" src="@/assets/images/default-product-image.jpg">
                </template>
            </div>
            <div class="details col-lg-8 col-xl-8 col-sm-12 col-md-12">
                <p v-if="isCurrentUserDealer && price > 0" class="price">
                    {{ price | fractionSizeSpace }}
                    {{ makeDeclension(price, "балл", "балла", "баллов") }}
                </p>
                <div v-if="isCurrentUserDealer && price_for_unit > 0" class="offers f-size-18">
                    {{ price_for_unit }} за 1шт.
                </div>
                <p v-if="isCurrentUserDealer && count" class="offers-count">
                    В наличии: {{ count.count }} шт.
                </p>
                <template v-if="isCurrentUserStructuralSubdivisionUser">
                    <p class="price f-size-18">
                        Доступно для заказа:
                    </p>
                    <p class="price f-size-18">
                        {{ balance }}
                        <small>шт.</small>
                    </p>
                </template>
                <div class="discount-section" v-if="!closePurchaseByDate()">
                    <div @click="minus" class="minus">
                        <img src="@/assets/images/minus.svg" alt="Убрать товар">
                    </div>
                    <div class="number" v-if="isCurrentUserDealer">
                        <p>{{ currentQuantity }}</p>
                    </div>
                    <input
                        v-if="isCurrentUserStructuralSubdivisionUser"
                        :value="currentQuantity"
                        class="quantity"
                        type="text"
                        name="quantityInput"
                        @input="onQuantityChange"
                    />
                    <div @click="plus" class="plus">
                        <img src="@/assets/images/plus.svg" alt="Добавить товар">
                    </div>
                </div>
                <div v-if="!closePurchaseByDate() || name === 'test'">
                    <div v-if="isAvailable || isCurrentUserStructuralSubdivisionUser">
                        <template v-if="price > 0">
                            <button
                                v-if="!showSizeSelect"
                                class="send-to-basket"
                                type="button"
                                @click="addInBasket"
                            >
                                В корзину
                            </button>
                            <div v-else class="size-select">
                                <p class="size-select__label">Выберите размер:</p>
                                <v-select
                                    v-model="selectedSize"
                                    label="name"
                                    class="sizes size-select__select"
                                    append-to-body
                                    ref="sizeSelect"
                                    :options="sizes"
                                    @input="selectSize"
                                />
                            </div>
                        </template>
                        <button v-if="price == 0" class="item-in-basket" type="button" disabled>
                           Недоступен для заказа
                        </button>
                    </div>
                </div>
                <button
                    v-if="!closePurchaseByDate() && isCurrentUserDealer && !isAvailable"
                    class="item-in-basket default-cursor"
                    type="button"
                >
                    Временно отсутствует
                </button>
                <div v-if="closePurchaseByDate()">
                    <div class="closeOrderLabel" type="button" @click="showWarningAlert()">
                        Недоступно до <br>{{ closeOrdersDealerByDate }}
                    </div>
                </div>
                <br/><br/>
                <p class="description">{{ shortDescription }}</p>
                <p v-if="isLongTermProduction" class="long-time-production">
                    Внимание! Товар длительного производства.
                </p>
                <p v-if="isLimited" class="limited">
                    Внимание! Товар ограниченной серии.
                </p>
            </div>
        </div>
        <p class="desc-title">Описание</p>
        <p class="description">{{ description }}</p>
        <div v-if="files && files.length > 0">
            <div class="row pdx-15 pdx-15-sm">
                <p class="desc-title">Скачать</p>
                <p class="file-link" v-for="(file, key) in files" :key="key">
                    <a
                        :href="getFileSrc(file.id)"
                        @click.prevent="downloadFile(file)"
                    >
                        {{ file.file_name }}
                    </a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api'
import {mapActions, mapGetters} from "vuex";
import {setLoading, removeLoading} from "@/lib/loading";
import debounce from "lodash.debounce";
import {success} from "@/lib/toasted";
import {showPurchaseByDate} from "@/lib/swal2";

export default {
    name: "Product",
    async mounted() {
        await this.refreshData();
    },
    data: () => ({
        id: null,
        name: null,
        sectionPath: '',
        categoryPath: '',
        productPath: '',
        price: null,
        price_for_unit: null,
        quantity: null,
        count: null,
        description: null,
        shortDescription: null,
        isLongTermProduction: 0,
        isLimited: 0,
        images: [],
        files: [],
        sizes: [],
        quantityInCart: 0,
        isAvailable: null,
        balance: null,
        selectedImage: null,
        initialViewIndex: 0,
        selectedSize: null,
        sizeDisabled: false,
        showSizeSelect: false
    }),
    methods: {
        ...mapActions('cart', ['goToCart', 'addProduct', 'removeProduct']),
        ...mapActions('common', ['downloadFileFromUrl']),
        async refreshData() {
            setLoading();

            const {section, category, product} = this.$route.params;

            this.sectionPath = section;
            this.categoryPath = category;
            this.productPath = product;

            try {
                const {
                    data: {
                        data: {
                            id,
                            name,
                            price,
                            price_for_unit,
                            quantity,
                            description,
                            short_description,
                            images = [],
                            files = [],
                            sizes = [],
                            quantity_in_cart = 0,
                            is_available,
                            balance,
                            count,
                            is_long_term_production,
                            is_limited_series,
                        }
                    }
                } = await api.catalog.product(section, category, product);

                this.id = id;
                this.name = name;
                this.price = price;
                this.quantity = quantity;
                this.price_for_unit = price_for_unit;
                this.description = description;
                this.shortDescription = short_description;
                this.images = images;
                this.files = files;
                this.sizes = sizes;
                this.quantityInCart = quantity_in_cart;
                this.isAvailable = is_available;
                this.balance = balance;
                this.count = count;
                this.isLongTermProduction = is_long_term_production;
                this.isLimited = is_limited_series;

                if (images && images.length > 0) {
                    this.selectImage(images[0]);
                }

                removeLoading();
            } catch (e) {
                const {response} = e;
                removeLoading();

                if (response && (response.status == 404 || response.data.status == 'error')) {
                    this.$router.push('/404');
                }
            }
        },
        selectImage(image, key) {
            this.selectedImage = image
            this.initialViewIndex = key;
        },
        async downloadFile(file) {
            try {
                await this.downloadFileFromUrl({fileName: file.file_name, fileUrl: this.getFileSrc(file.id)});
            } catch (error) {
            }
        },
        show() {
            this.$viewerApi({
                images: this.imageSrcs,
                options: {
                    initialViewIndex: this.initialViewIndex,
                    title: false,
                    toolbar: {
                        zoomIn: {
                            size: 'extra-large',
                        },
                        zoomOut: {
                            size: 'large',
                        },
                        oneToOne: {
                            size: 'large',
                        },
                        reset: {
                            size: 'large',
                        },
                        prev: {
                            size: 'large',
                        },
                        play: {
                            size: '40px',
                        },
                        next: {
                            size: 'large',
                        },
                        rotateLeft: false,
                        rotateRight: false,
                        flipHorizontal: false,
                        flipVertical: false,
                    },
                }
            })
        },
        openSizeSelect() {
            this.$refs.sizeSelect.open = true;
        },
        async addInBasket() {
            if (!this.checkIfSizeChoiceIsRequired()) {
                if (this.isCurrentUserStructuralSubdivisionUser && this.balance <= this.quantityInCart)
                    return false;
                if (await this.addProduct({productId: this.id, size: this.sizeId})) {
                    await this.changeQuantity(1)
                }
            }
        },
        checkIfSizeChoiceIsRequired() {
            if (this.sizesExist && !this.selectedSize) {
                this.showSizeSelect = true;
                this.openSizeSelect();
                success('Пожалуйста выберите размер.');

                return true;
            }

            return false;
        },
        async selectSize(size) {
          if (size) {
            if (await this.addProduct({productId: this.id, size: size.id})) {
              await this.changeQuantity(1, size.id)
            }
          } else {
            this.selectedSize = null;
          }
        },
        async plus() {
            if (!this.checkIfSizeChoiceIsRequired()) {
                if (this.isCurrentUserStructuralSubdivisionUser && this.balance <= this.quantityInCart)
                    return false;

                if (await this.addProduct({productId: this.id, size: this.sizeId})) {
                  await this.changeQuantity(1, this.sizeId)
                }
            }
        },
        async minus() {
            if (this.currentQuantity === 1) {
                if (await this.removeProduct({productId: this.id, sizeId: this.sizeId, completely: true})) {
                    await this.setQuantity(0, this.sizeId)
                    if (this.sizes.length > 1) {
                        this.selectedSize = null;
                    }
                }
            } else if (await this.removeProduct({productId: this.id, size: this.sizeId})) {
                await this.changeQuantity(-1, this.sizeId)
            }
        },
        onQuantityChange(event) {
            if (!event.target.value) {
                return;
            }
            if (isNaN(event.target.value) || event.target.value <= 0 || event.target.value > this.balance) {
                event.target.value = this.currentQuantity
                return;
            }
            if (!/[0-9]/.test(event.target.value)) {
                event.target.value = this.currentQuantity;
                return;
            }
            let quantity = Number(event.target.value);

            const currentQuantity = this.currentQuantity;

            this.setQuantity(quantity, this.sizeId)

            const productId = this.id;

            const difference = Math.abs(currentQuantity - quantity);

            const debouncedQuantity = debounce(async () => {
                if (quantity > currentQuantity) {
                  if (this.checkIfSizeChoiceIsRequired()) {
                    this.setQuantity(currentQuantity, this.sizeId)
                    return false;
                  }

                  if (await this.addProduct({productId, quantity: difference, size: this.sizeId})) {
                    this.setQuantity(currentQuantity + difference, this.sizeId);
                  }
                } else if (quantity < currentQuantity) {
                    if (await this.removeProduct({productId, quantity: difference, size: this.sizeId})) {
                        this.setQuantity(currentQuantity - difference, this.sizeId);
                    }
                } else {
                    this.setQuantity(currentQuantity, this.sizeId);
                }
            }, 500)
            debouncedQuantity();

        },
        changeQuantity(quantity, sizeId = null) {
          if (sizeId) {
            let newQuantity = (this.quantityInCart[sizeId] || 0) + quantity;
            this.quantityInCart = {...this.quantityInCart, [sizeId]: newQuantity};
          } else {
            this.quantityInCart += quantity;
          }
        },
        setQuantity(quantity, sizeId = null) {
          if (sizeId) {
              this.quantityInCart = {...this.quantityInCart, [sizeId]: quantity};
          } else {
              this.quantityInCart = quantity;
          }
        },
        closePurchaseByDate() {
          return this.closeOrdersDealerByDate !== '' &&
            !(this.isCurrentUserDealer && this.count);
        },
        showWarningAlert() {
            showPurchaseByDate('Прием заказов прекращен до ' + this.closeOrdersDealerByDate + '. С информацией можно ознакомиться в разделе <a style="text-decoration: underline;" href="/how-to-order">«Как заказать?»</a>')
        }
    },
    computed: {
        ...mapGetters('catalog', ['currentProductId']),
        ...mapGetters('common', ['getProductImageSrc', 'getFileSrc', 'makeDeclension']),
        ...mapGetters('cart', ['isProductInCart']),
        ...mapGetters('builtInParameter', ['closeOrdersDealerByDate']),
        hasProductImages() {
            return !!this.images && this.images.length > 0;
        },
        itemIsInCart() {
            return this.isProductInCart(this.id);
        },
        currentQuantity() {
          if (typeof this.quantityInCart === 'object') {
            if (this.selectedSize) {
              return this.quantityInCart[this.selectedSize.id] || 0;
            }
          } else {
            return this.quantityInCart || 0;
          }

          return 0;
        },
        imageSrcs() {
            let that = this;

            return this.images.map((item) => {
                return that.getProductImageSrc(item, 0);
            });
        },
        sizesExist() {
            return this.sizes && this.sizes.length > 0
        },
        sizeId() {
            return this.selectedSize ? this.selectedSize.id : null;
        }
    },
    watch: {
        $route() {
            this.refreshData();
        }
    },
    metaInfo() {
        return {
            title: this.name + ` - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style lang="sass">
@import "@/assets/sass/components/product-card.sass"
</style>

<style lang="sass" scoped>
.title-without-line:after
    width: 50%

.f-size-18
    font-size: 18px

.product_example
    display: flex
    position: relative

.vertical-imgs-section
    height: auto
    width: 60px

.vertical-imgs
    width: 100%
    margin-bottom: 22px

.item
    width: 100%
    height: 100%

.big-img
    height: 280px
    width: 220px
    margin-left: 20px

.big-img img
    max-width: 220px
    height: auto

.vertical-imgs img,
.big-img img
    cursor: pointer

.discount-section
    height: 25px
    margin-top: 20px
    margin-bottom: 20px

.minus, .plus
    width: 25px
    height: 100%
    cursor: pointer

.minus, .number, .plus
    float: left
.number
    padding: 0 15px
    height: 100%
    display: flex
    font-size: 16px
    justify-content: space-around
    align-items: center

.number p
    margin: 0

.file-link
    font-size: 16px
    font-weight: 400 !important
    font-style: normal
    font-stretch: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    color: #000000
    margin-top: 25px
    margin-left: 10px

.desc-title
    font-size: 16px
    font-style: normal
    font-stretch: normal
    line-height: 1.5
    letter-spacing: normal
    text-align: left
    font-weight: bold
    color: #000000
    margin-top: 25px

.description
    font-size: 19px
    font-weight: normal
    font-style: normal
    font-stretch: normal
    line-height: 1.63
    letter-spacing: normal
    text-align: left
    white-space: pre-wrap
.long-time-production,
.limited
    font-size: 16px
    color: var(--red)
    line-height: 18px
    font-weight: bold
.sizes
    width: 250px

.offers
    display: block
    font-size: 16px
    font-weight: 700
    line-height: 25px
    padding-top: 20px
    overflow: hidden

.popular_section
    padding-left: 0
    padding-right: 0

.pdx-15
    padding: 0 20px

.go-back
    margin-bottom: 0
    margin-top: 2.5rem
    font-weight: bold
    position: relative
    z-index: 5
    width: 100%

.go-back:before
    content: ''
    background-image: url('~@/assets/images/arrow_down.svg')
    width: 10px
    height: 6px
    display: block
    position: absolute
    left: -15px
    top: 3px
    transform: rotate(90deg) translateY(0px)
    transition: all 0.3s ease

.go-back:hover::before
    transform: rotate(90deg) translateY(5px)
    transition: all 0.3s ease

.default-cursor
    cursor: default

@media (max-width: 920px)
    .description
        font-size: 16px

.quantity
    width: 80px
    height: 25px
    text-align: center
    float: left

input[type=number].quantity::-webkit-inner-spin-button, input[type=number].quantity::-webkit-outer-spin-button
    -webkit-appearance: none
        margin: 0

.size-select
    &__label
        font-size: 14px
        font-weight: bold
        margin-bottom: 0.3rem

    &__select
        margin-top: 9px
        width: 150px
</style>
